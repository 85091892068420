import React from 'react';
import { useSelector } from 'react-redux';
import { useBi, useEnvironment } from '@wix/yoshi-flow-editor';
import { useCurrentStateAndParams } from '@wix/tpa-router/react';
import { groupCreatePostClick, groupFeedView } from '@wix/bi-logger-groups/v2';

import type { RicosEditorType } from '@wix/ricos';
import { EditorEventsProvider } from '@wix/ricos';

import { EFilterKeys } from 'api/feed/constants';
import {
  selectDialog,
  selectFeedPermissionsByGroup,
  selectGroupIdBySlug,
  selectTopic,
  selectFeed,
  selectFeedItems,
} from 'store/selectors';
import { GroupAppKey } from 'store/groups/types';

import { useController } from 'common/context/controller';
import { useDidUpdate } from 'common/hooks';

import { FeedItemEditorDialog } from 'common/components/FeedItemEditorDialog';
import { FeedItemList } from 'common/components/FeedItemList';

import { RestrictedState } from 'Group/Widget/RestrictedState';
import { Show } from 'wui/Show';

import { CreatePost } from './CreatePost';
import { AppliedFilters } from './AppliedFilters';
import { GroupTopics } from '../GroupTopics';
import { SeoPagination } from './SeoPagination';
import { Onboarding } from '../Onboarding';

export interface IGroupFeedProps {}

export const GroupFeed: React.FC<IGroupFeedProps> = () => {
  const bi = useBi();
  const { application$, feed$ } = useController();
  const { params } = useCurrentStateAndParams();
  const { isMobile } = useEnvironment();

  const groupId = useSelector(selectGroupIdBySlug(params.slug));
  const permissions = useSelector(selectFeedPermissionsByGroup(groupId));
  const dialog = useSelector(selectDialog('createPost'));
  const topic = useSelector(selectTopic(params[EFilterKeys.TOPICS]));

  const state = useSelector(selectFeed);
  const items = useSelector(selectFeedItems);

  const $editor = React.useRef<RicosEditorType>(null);

  useDidUpdate(() => {
    if (permissions.canViewPosts) {
      feed$.fetch(groupId);
    }
  }, [permissions.canViewPosts]);

  React.useEffect(() => {
    bi.report(
      groupFeedView({
        group_id: groupId,
        origin: 'discussion_tab',
      }),
    );
  }, []);

  return (
    <RestrictedState application={GroupAppKey.FEED_APP}>
      <AppliedFilters />

      <Show if={permissions.canCreatePosts}>
        <CreatePost
          data-hook="create-post-card"
          onClick={handleCreatePostClick}
        />
      </Show>

      {isMobile ? (
        <>
          <Onboarding groupId={groupId} data-hook="onboarding-widget" />
          <GroupTopics groupId={groupId} />
        </>
      ) : null}

      <FeedItemList
        state={state}
        items={items}
        onCreatePost={handleCreatePostClick}
        onFetch={handleFetchMore}
        data-hook="feed-item-list"
      />
      <SeoPagination groupId={groupId} />
      <EditorEventsProvider>
        <FeedItemEditorDialog
          forTopic={topic}
          groupId={groupId}
          editorRef={$editor}
          isOpen={dialog.isOpen}
          biExtra={dialog.params}
          onClose={closeCreateModal}
          data-hook="feed-item-editor-dialog"
        />
      </EditorEventsProvider>
    </RestrictedState>
  );

  function handleFetchMore(cursor?: string) {
    feed$.fetch(groupId, { cursor }, true);
  }

  function handleCreatePostClick() {
    bi.report(
      groupCreatePostClick({
        groupId,
        origin: 'top_rce_area',
      }),
    );

    feed$.showCreatePostDialog(groupId, {
      origin: 'livesite_group_page',
      page_name: 'livesite_group_page',
    });
  }

  function closeCreateModal() {
    application$.closeDialog('createPost');
  }
};

GroupFeed.displayName = 'GroupFeed';
