import React from 'react';
import { useSelector } from 'react-redux';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { Route, useCurrentStateAndParams } from '@wix/tpa-router/react';

import { selectGroupIdBySlug } from 'store/selectors';

import { type TPAComponentProps } from 'wui/types';
import { Column, Layout } from '../Layout';

import { AboutGroup } from './AboutGroup';
import { GroupTopics } from './GroupTopics';
import { GroupMembers } from './GroupMembers';
import { UpcomingGroupEvents } from './UpcomingGroupEvents';
import { PostPage } from './PostPage';
import { GroupFeed } from './GroupFeed';
import { Onboarding } from './Onboarding';

export function DiscussionPage(props: TPAComponentProps) {
  const { isMobile } = useEnvironment();
  const { params } = useCurrentStateAndParams();

  const groupId = useSelector(selectGroupIdBySlug(params.slug));

  if (isMobile) {
    return (
      <Layout {...props}>
        <Column>
          <Route state="group.discussion.feed" component={GroupFeed} />
          <Route state="group.discussion.post" component={PostPage} />
          <AboutGroup groupId={groupId} />
          <GroupMembers groupId={groupId} />
          <UpcomingGroupEvents groupId={groupId} />
        </Column>
      </Layout>
    );
  }

  return (
    <Layout {...props}>
      <Column>
        <Route state="group.discussion.feed" component={GroupFeed} />
        <Route state="group.discussion.post" component={PostPage} />
      </Column>
      <Column>
        <Onboarding groupId={groupId} data-hook="onboarding-widget" />
        <AboutGroup groupId={groupId} />
        <GroupMembers groupId={groupId} />
        <GroupTopics groupId={groupId} />
        <UpcomingGroupEvents groupId={groupId} />
      </Column>
    </Layout>
  );
}

DiscussionPage.displayName = 'DiscussionPage';
