import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation, useBi } from '@wix/yoshi-flow-editor';
import { useController } from 'common/context/controller';
import { useRouter } from '@wix/tpa-router/react';

// UI
import { Step, type IStepProps } from './Step';
import {
  Plus as PlusIcon,
  User as UserIcon,
  Like as LikeIcon,
  Edit as EditIcon,
  Image as ImageIcon,
  Settings as SettingsIcon,
} from '@wix/wix-ui-icons-common/on-stage';

// bi
import { onboardingClickSrc111Evid533 } from '@wix/bi-logger-groups-data/v2';

// selectors
import {
  selectHasAdminRole,
  selectGroup,
  selectOnboarding,
} from 'store/selectors';

// types
import {
  type Step as StepType,
  type Status,
  StepKey,
} from '@wix/ambassador-groups-onboarding-v1-onboarding/types';

export type OnboardingStepProps = {
  step: StepType;
  groupId: string;
} & Partial<IStepProps>;

export function OnboardingStep({
  step,
  groupId,
  ...rest
}: OnboardingStepProps) {
  const { onboarding$, feed$, application$ } = useController();
  const isAdmin = useSelector(selectHasAdminRole(groupId));
  const group = useSelector(selectGroup(groupId));
  const {
    data: { steps },
  } = useSelector(selectOnboarding);

  const { t } = useTranslation();
  const bi = useBi();
  const router = useRouter();

  const completedStepsLength = steps.filter(
    (step) => step.status === 'COMPLETED',
  ).length;

  switch (step.stepKey) {
    case StepKey.CREATE_POST:
      return (
        <Step
          {...rest}
          data-step-type={step.stepKey}
          icon={<PlusIcon />}
          status={step.status}
          title={
            isAdmin
              ? t('groups-web.onboarding.steps.step.create-post.admin.title')
              : t('groups-web.onboarding.steps.step.create-post.member.title')
          }
          onClick={() =>
            handleStep({
              step,
              callback: () =>
                feed$.showCreatePostDialog(groupId, {
                  page_name: 'livesite_group_page',
                  origin: 'onboarding',
                  step: JSON.stringify(step),
                }),
            })
          }
        />
      );

    case StepKey.UPDATE_DESCRIPTION:
      return (
        <Step
          {...rest}
          data-step-type={step.stepKey}
          icon={<EditIcon />}
          status={step.status}
          title={
            isAdmin
              ? t(
                  'groups-web.onboarding.steps.step.update-description.admin.title',
                )
              : t(
                  'groups-web.onboarding.steps.step.update-description.member.title',
                )
          }
          onClick={() =>
            handleStep({
              step,
              complete: true,
              callback: () =>
                router.go('group.about', {
                  openEditor: true,
                  origin: 'onboarding',
                }),
            })
          }
        />
      );

    case StepKey.REACT_TO_POST:
      return (
        <Step
          {...rest}
          data-step-type={step.stepKey}
          icon={<LikeIcon />}
          status={step.status}
          disabled
          title={
            isAdmin
              ? t('groups-web.onboarding.steps.step.react-to-post.admin.title')
              : t('groups-web.onboarding.steps.step.react-to-post.member.title')
          }
          onClick={() => handleStep({ step })}
        />
      );

    case StepKey.INVITE_MEMBERS:
      return (
        <Step
          {...rest}
          data-step-type={step.stepKey}
          icon={<UserIcon />}
          status={step.status}
          title={
            isAdmin
              ? t('groups-web.onboarding.steps.step.invite-members.admin.title')
              : t(
                  'groups-web.onboarding.steps.step.invite-members.member.title',
                )
          }
          onClick={() =>
            handleStep({
              step,
              callback: () =>
                application$.showDialog({
                  dialog: 'inviteMembers',
                  params: {
                    biExtra: {
                      step: JSON.stringify(step),
                      origin: 'onboarding',
                    },
                  },
                }),
            })
          }
        />
      );

    case StepKey.UPDATE_COVER_IMAGE:
      return (
        <Step
          {...rest}
          data-step-type={step.stepKey}
          icon={<ImageIcon />}
          status={step.status}
          title={
            isAdmin
              ? t(
                  'groups-web.onboarding.steps.step.update-cover-image.admin.title',
                )
              : t(
                  'groups-web.onboarding.steps.step.update-cover-image.member.title',
                )
          }
          onClick={() =>
            handleStep({
              step,
              callback: () =>
                application$.showDialog({
                  dialog: 'groupSettings',
                  params: {
                    biExtra: {
                      origin: 'onboarding',
                    },
                  },
                }),
            })
          }
        />
      );

    case StepKey.EXPLORE_DASHBOARD:
      return (
        <Step
          {...rest}
          data-step-type={step.stepKey}
          icon={<SettingsIcon />}
          status={step.status}
          title={
            isAdmin
              ? t(
                  'groups-web.onboarding.steps.step.explore-dashboard.admin.title',
                )
              : t(
                  'groups-web.onboarding.steps.step.explore-dashboard.member.title',
                )
          }
          onClick={() =>
            handleStep({
              step,
              complete: true,
              callback: () =>
                application$.showDialog({
                  dialog: 'groupSettings',
                  params: { activeTab: 'settings' },
                }),
            })
          }
        />
      );

    default:
      return null;
  }

  function handleStep({
    step,
    complete,
    callback,
  }: {
    step: StepType;
    complete?: boolean;
    callback?: () => void;
  }) {
    bi.report(
      onboardingClickSrc111Evid533({
        user_role: group.role,
        group_id: groupId,
        total_steps: steps.length,
        completed_steps_cnt: completedStepsLength,
        steps_list: JSON.stringify(steps),
        step: JSON.stringify(step),
      }),
    );

    if (callback) {
      callback();
    }

    if (complete) {
      completeStep(step.stepKey, step.status);
    }
  }

  function completeStep(stepKey?: StepKey, status?: Status) {
    const isStepActive = status === 'ACTIVE';

    if (isStepActive) {
      onboarding$.completeStep({
        onboardingId: groupId,
        stepKey,
      });
    }
  }
}

OnboardingStep.displayName = 'OnboardingStep';
